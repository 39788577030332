import React, { useContext, useState, Fragment, useEffect } from "react";
import "./shot-chart.scss";
import { Input, Button, Col, Row, Divider } from "antd";
import {
  ShotChartContextProvider,
  ShotChartContext,
} from "../../../coach-context/group-context/shot-chart-context";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { UserContext } from "../../../context/user-context";
import Chart from "../../../admin-module/shared/chart";
TimeAgo.addDefaultLocale(en);
const { TextArea } = Input;
const style = {
  padding: "5px 0",
};
const ShotChartPage = () => {
  const { color, lightButtonBackground, selectedSport } =
    useContext(UserContext);
  const { shotChart, setLoading } = useContext(ShotChartContext);
  const [make7Days, setMake7Days] = useState(0);
  const [attempts7Days, setAttempts7Days] = useState(0);
  const [make30Days, setMake30Days] = useState(0);
  const [attempts30Days, setAttempts30Days] = useState(0);
  const [make6Months, setMake6Months] = useState(0);
  const [attempts6Months, setAttempts6Months] = useState(0);
  return (
    <div className="shot-chart-main">
      <div style={{ display: "flex", marginBottom: 32 }}>
        <h1>Shot Chart</h1>
      </div>
      <Chart
        shotChart={shotChart}
        setLoading={setLoading}
        edit={false}
        teamSessions={true}
        make7Days={make7Days}
        setMake7Days={setMake7Days}
        attempts7Days={attempts7Days}
        setAttempts7Days={setAttempts7Days}
        make30Days={make30Days}
        setMake30Days={setMake30Days}
        attempts30Days={attempts30Days}
        setAttempts30Days={setAttempts30Days}
        make6Months={make6Months}
        setMake6Months={setMake6Months}
        attempts6Months={attempts6Months}
        setAttempts6Months={setAttempts6Months}
      />
      <Row gutter={16} className="score-row">
        <Col className="gutter-row" span={8}>
          <h2>Last 7 Days</h2>
          <div style={style} className="dotted-box">
            <p>
              {isNaN(Math.round(make7Days * 100) / attempts7Days)
                ? 0
                : (Math.round(make7Days * 100) / attempts7Days).toFixed(1)}
              %
            </p>
            <Divider />
            <Row>
              <Col span={12}>
                <span className="count">{make7Days}</span>
                <span className="heading">Shots Made</span>
              </Col>
              <Col span={12}>
                <span className="count">{attempts7Days}</span>
                <span className="heading">Shots Attempted</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <h2>Last 30 Days</h2>
          <div style={style} className="dotted-box">
            {" "}
            <p>
              {isNaN(Math.round(make30Days * 100) / attempts30Days)
                ? 0
                : (Math.round(make30Days * 100) / attempts30Days).toFixed(1)}
              %
            </p>
            <Divider />
            <Row>
              <Col span={12}>
                <span className="count">{make30Days}</span>
                <span className="heading">Shots Made</span>
              </Col>
              <Col span={12}>
                <span className="count">{attempts30Days}</span>
                <span className="heading">Shots Attempted</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <h2>Last 6 Months</h2>
          <div style={style} className="dotted-box">
            {" "}
            <p>
              {isNaN(Math.round(make6Months * 100) / attempts6Months)
                ? 0
                : (Math.round(make6Months * 100) / attempts6Months).toFixed(1)}
              %
            </p>
            <Divider />
            <Row>
              <Col span={12}>
                <span className="count">{make6Months}</span>
                <span className="heading">Shots Made</span>
              </Col>
              <Col span={12}>
                <span className="count">{attempts6Months}</span>
                <span className="heading">Shots Attempted</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ShotChart = () => {
  return (
    <ShotChartContextProvider>
      <ShotChartPage />
    </ShotChartContextProvider>
  );
};
export default ShotChart;
