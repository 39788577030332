import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Progress,
  Radio,
  Select,
  Steps,
  Tabs,
  Upload,
  message,
} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import UploadFile from "../../../assets/images/upload.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Player from "../../Player/Player";
import Compressor from "compressorjs";
import { UserContext } from "../../../context/user-context";
// import ReactQuill from "react-quill";
import ReactQuill from "react-quill";
import "./create-new-rotator.scss";
import { useForm } from "antd/es/form/Form";
import {
  CreateRotatorContext,
  CreateRotatorContextProvider,
} from "../../../context/home-rotator-context/create-rotator-context";
import ReactPlayer from "react-player";
import { formPostData } from "../../../util/api-calls";
import Expand from "../../../assets/images/expand_video.svg";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";

const { Dragger } = Upload;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
// const dateFormat = "ddd. MMMM D, YYYY";
const dateFormat = "ddd. MMM D, YYYY h:mm A";

const CreateNewRotatorPage = ({ existingTaskModal, setExistingTaskModal }) => {
  const { darkButtonBackground, selectedSport } = useContext(UserContext);
  const {
    uploadVideo,
    sponsorsList,
    sportsList,
    sport,
    setSport,
    sponsor,
    setSponsor,
    sponsorUrl,
    setSponsorUrl,
    setSportError,
    setButtonText,
    setStartDate,
    videoPreview,
    resetForm,
    videoUrl,
    detailContent,
    form1,
    form,
    imagePreview,
    detailImgSrc,
    imageUrl,
    setTitle,
    setDetailVideoId,
    setDetailButtonLink,
    setDetailButtonText,
    setDetailContent,
    setImageUrl,
    setImgSrc,
    setDetailImgSrc,
    setVideoUrl,
    setImagePreview,
    setDetailImageUrl,
    setDetailTitle,
    setEndDate,
    setVideoPreview,
    imgSrc,
    detailImageUrl,
    createRotator,
    startDate,
    endDate,
    title,
    buttonText,
    sportError,
    detailMediaError,
    setDetailMediaError,
    detailVideoId,
    buttonDisabled,
    videoId,
    setVideoId,
    abortVideo,
    asssignee,
    setAssignee,
  } = useContext(CreateRotatorContext);
  const navigate = useNavigate();
  const wrapperRef = React.useRef(null);
  const initialItems = [
    {
      label: "Rotator 1",
      key: "1",
      closable: false,
    },
  ];

  const [current, setCurrent] = useState(0);
  const [addMore, setAddMore] = useState(true);
  const [uploadingStart, setUploadingStart] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [detailImagePreview, setDetailImgaePreview] = useState(false);
  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);
  const [videoDeleted, setVideoDeleted] = useState(false);
  const onChange = (value) => {
    setCurrent(value);
  };
  let sportOption = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOption.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  useEffect(() => {
    if (sportsList && sportsList.length > 0) {
      setSport(sportsList[0].id);
      form.setFieldsValue({
        sport: sportsList[0].name,
      });
    }
  }, [sportsList]);
  let sponsorOption = [];

  for (let i = 0; i < sponsorsList.length; i++) {
    sponsorOption.push({
      value: sponsorsList[i].id,
      label: sponsorsList[i].name,
    });
  }
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: ["Neue Haas Grotesk Display"] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "indent",
    "link",
    "image",
    "font",
  ];

  const handleProcedureContentChange = (content) => {
    setDetailContent(content);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  async function handleBeforeUploadHome(file) {
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      width: 2796,
      height: 1290,
      success: (compressedResult) => {
        setImageUrl(compressedResult);
      },
    });
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    setImagePreview(true);
    reader.readAsDataURL(file);
    return false;
  }
  async function handleBeforeUpload(file) {
    setVideoDeleted(false);
    if (file.type === "image/png" || file.type === "image/jpeg") {
      setVideoUrl();
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        width: 2796,
        height: 1290,
        success: (compressedResult) => {
          setDetailImageUrl(compressedResult);
        },
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        setDetailImgSrc(reader.result);
      };
      setVideoPreview(true);
      setDetailMediaError(false);
      reader.readAsDataURL(file);
      return false;
    } else {
      setDetailImgSrc();
      setUploadingStart(true);
      setVideoUrl(file);
      setVideoPreview(true);
      setDetailMediaError(false);
      await uploadVideo(file, handleSuccessfullBytes, uploadCompleteDone);
    }
  }
  const handleSuccessfullBytes = (bytes, totalBytes) => {
    const bytesInPercentage = Math.round((bytes / totalBytes) * 100);
    setPercentage(bytesInPercentage);
  };
  const uploadCompleteDone = (isCompleted, detailVideoId, url) => {
    setVideoSrc(url);
    setVideoPreview(true);
    console.log("status", isCompleted);
    console.log("detailVideoId", detailVideoId);
    setUploadComplete(isCompleted);
    console.log(detailVideoId);
    const video_id = detailVideoId.split("/");
    setDetailVideoId(video_id[2]);
    setUploadingStart(false);
  };
  const onChangeSport = (value) => {
    setSportError(false);
    setSport(value);
  };
  const onChangeSponsor = (value) => {
    setSponsor(value);
    for (let i = 0; i < sponsorsList.length; i++) {
      if (value === sponsorsList[i].id) {
        setSponsorUrl(sponsorsList[i].website);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({ sponsor_url: sponsorUrl });
  }, [sponsorUrl]);

  let newTabIndex = useRef(2);
  const onChangeTabs = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };
  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    newTabIndex = `${newTabIndex.current--}`;
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };
  const add = () => {
    setAddMore(true);
    const newActiveKey = `${newTabIndex.current++}`;
    setItems([
      ...items,
      {
        label: `Rotator ${newActiveKey}`,
        // children: 'New Tab Pane',
        closable: true,
        key: newActiveKey,
      },
    ]);
    setActiveKey(newActiveKey);
  };
  const handleFullscreen = () => {
    console.log(wrapperRef.current);
    screenfull.toggle(findDOMNode(wrapperRef.current));
  };
  const clickNext = () => {
    if (
      startDate !== "" &&
      endDate !== "" &&
      title !== "" &&
      buttonText !== ""
      // &&
      // sport !== ""
    ) {
      setCurrent(1);
    }
  };
  const changeStartDate = (e) => {
    setStartDate(new Date(e).toISOString());
  };
  const changeEndDate = (e) => {
    setEndDate(new Date(e).toISOString());
  };
  const resetHomeDragger = () => {
    // setVideoUrl();
    // setDetailVideoId("");
    // setDetailImageUrl();
    // setVideoPreview(false);
    // setDetailImgSrc();
    setImageUrl();
    setImgSrc();
    setImagePreview(false);
  };
  const deleteVideo = async () => {
    const response = await axios.delete(`https://api.vimeo.com${videoId}`, {
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        "Content-Type": "application/json",
        Authorization: `Bearer be82d2e853d829ed867903ec070a64f7`,
      },
    });
    setVideoId();
    setVideoDeleted(true);
    return response?.data;
  };
  const resetLandingDragger = () => {
    abortVideo();
    setPercentage(0);
    setDetailImgSrc();
    setDetailImageUrl();
    setVideoPreview(false);
    setVideoUrl();
    setDetailVideoId("");
    if (videoId) {
      deleteVideo();
    }
  };
  useEffect(() => {
    if (videoDeleted === true) {
      setVideoPreview(false);
    }
  }, [videoPreview]);
  // console.log(videoUrl)
  return (
    <div className="new-rotator-main">
      <Form
        form={form}
        name="dynamic_rule"
        className="new-rotator-form"
        onFinish={createRotator}
      >
        {/* <Form.Item
          {...formItemLayout}
          name="sport"
          label="Sport"
          rules={[
            {
              required: true,
              message: "Please select sport",
            },
          ]}
        >
          <Select
            // placeholder="Please select sport"
            placeholder="Basketball"
            optionFilterProp="children"
            onChange={onChangeSport}
            size={"large"}
            suffixIcon={<img src={DownArrow} />}
            style={{ height: 38, width: "100%", marginTop: 8 }}
            className="custom-select"
            options={sportOption}
            defaultActiveFirstOption={true}
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
          />
        </Form.Item>
        {sportError === true && (
          <span className="error-message">Please select sport.</span>
        )} */}

        <div className="tasks-list">
          <h3 style={{ marginBottom: 32 }}>Rotators</h3>
          <div className="add-rotator-div">
            {/* <Button className="rotator-value">Rotator 1</Button>
          {showMore &&
          <Button className="rotator-value">Rotator 2</Button>
          }
          <Button className="new-rotator" onClick={()=>setShowMore(true)}>+ Add more</Button> */}
            {/* 
          <Tabs
            hideAdd
            type="editable-card"
            onChange={onChangeTabs}
            activeKey={activeKey}
            onEdit={onEdit}
            items={items}
            style={{ width: newTabIndex.current >= 6 ? "81%" : "auto" }}
            className={selectedSport === 1 ? "orange-theme" : "blue-theme"}
          /> */}
            {/* {newTabIndex.current <= 3 && 
          <Button onClick={add} className="new-rotator"
           disabled={addMore}
           >
            + Add more
          </Button> 
}*/}
          </div>
          <Steps
            current={current}
            // onChange={onChange}
            // direction="vertical"
            className={
              "rotator-form-steps " +
              (selectedSport === 1 ? "orange-theme" : "blue-theme")
            }
            items={[
              {
                description: "Home Screen",
                // description,
                // disabled: true,
              },
              {
                description: "Landing Screen",
                // description,
              },
            ]}
          />
        </div>
        {current === 0 ? (
          <>
            <Form.Item
              {...formItemLayout}
              name="display_to"
              label="Display to"
              className="media-div"
              style={{ marginTop: 8 }}
            >
              <Radio.Group
                onChange={(e) => setAssignee(e.target.value)}
                value={asssignee}
                defaultValue={asssignee}
                className={"custom-radio"}
              >
                <Radio value={"player"}>Players</Radio>
                <Radio value={"coach"} style={{ marginLeft: 16 }}>
                  Coach
                </Radio>
                <Radio value={"both"} style={{ marginLeft: 16 }}>
                  Both
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title.",
                },
              ]}
            >
              <Input
                className="title"
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Please enter title"
                maxLength={255}
              />
            </Form.Item>
            {/* <Form.Item name={"visibility"}>
                  <Checkbox className="custom-checkbox">Visible to coaches only</Checkbox>
                </Form.Item> */}
            <Form.Item
              {...formItemLayout}
              label="Add Media"
              className="media-div"
            >
              <div className={imagePreview ? "uploader-div" : ""}>
                <Dragger
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUploadHome}
                  accept="image/png, image/jpeg"
                  showUploadList={false}
                  className={
                    selectedSport === 1 ? "orange-dragger" : "blue-dragger"
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <img
                      src={UploadFile}
                      alt=""
                      className={
                        selectedSport === 1
                          ? "orange-theme-icon"
                          : "blue-theme-icon"
                      }
                    />
                  </p>
                  <div style={{ textAlign: "left", padding: "12px 0" }}>
                    {" "}
                    <p className="ant-upload-text">
                      Drag and Drop or
                      <span>Browse</span>
                    </p>
                    <p className="ant-upload-hint">Upload photo (16:9 ratio)</p>
                  </div>
                </Dragger>
                {imagePreview && (
                  <Card
                    hoverable
                    className="video-cards"
                    cover={
                      <div
                        style={{
                          overflow: "hidden",
                          height: "250px",
                          borderRadius: 8,
                        }}
                      >
                        {/* {detailVideoId && ( */}
                        <span className="top-cancel-btn">
                          <CloseOutlined onClick={resetHomeDragger} />
                        </span>
                        {/* )} */}
                        <Image
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          className="img"
                          src={imgSrc}
                        />
                      </div>
                    }
                  >
                    <div className="name" title={imageUrl?.name}>
                      {imageUrl?.name}
                    </div>
                    <div className="size">
                      {(imageUrl?.size / 1000).toFixed(1) + "KB"}
                    </div>
                  </Card>
                )}
              </div>
            </Form.Item>
            {/* <Form.Item                 {...formItemLayout}

name={"auto_change"} label="Autochange Rotator After" className="custom-label">
                <Input addonBefore={"sec"} placeholder="Please enter seconds" className="add-on-input"/>
              </Form.Item> */}
            <Form.Item
              {...formItemLayout}
              name="action_button_text"
              label="Button Text"
              className="custom-label"
              rules={[
                {
                  required: true,
                  message: "Please enter button text.",
                },
              ]}
            >
              <Input
                className="title"
                onChange={(e) => setButtonText(e.target.value)}
                placeholder="Please enter button text"
                maxLength={255}
              />
            </Form.Item>
            <h3>Time Limit</h3>
            <div style={{ display: "flex", gap: 12 }}>
              <Form.Item
                {...formItemLayout}
                name="start_date"
                label="Starting on"
                style={{ width: 400 }}
                className="custom-label"
                rules={[
                  {
                    required: true,
                    message: "Please select start date.",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  className={
                    "date-picker " +
                    (selectedSport === 1 ? "orange-picker" : "blue-picker")
                  }
                  rootClassName={
                    selectedSport === 1 ? "orange-picker" : "blue-picker"
                  }
                  format={dateFormat}
                  suffixIcon={<img src={Calendar} />}
                  style={{ height: "38px" }}
                  onChange={changeStartDate}
                  placeholder="Select start date"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="end_date"
                label="Ending on"
                className="custom-label"
                style={{ width: 400 }}
                rules={[
                  {
                    required: true,
                    message: "Please select end date.",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  className={
                    "date-picker " +
                    (selectedSport === 1 ? "orange-picker" : "blue-picker")
                  }
                  rootClassName={
                    selectedSport === 1 ? "orange-picker" : "blue-picker"
                  }
                  format={dateFormat}
                  suffixIcon={<img src={Calendar} />}
                  style={{ height: "38px" }}
                  onChange={changeEndDate}
                  placeholder="Select end date"
                />
              </Form.Item>
            </div>
            <Form.Item
              {...formItemLayout}
              name="sponsor_id"
              label="Presented by"
              className="custom-label"
            >
              <Select
                placeholder="Select Sponsor"
                optionFilterProp="children"
                onChange={onChangeSponsor}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size={"large"}
                suffixIcon={<img src={DownArrow} />}
                style={{ height: 38, width: "100%", marginTop: 8 }}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                className="custom-select"
                options={sponsorOption}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="sponsor_url"
              label="Sponsor’s URL"
              className="custom-label"
              rules={[
                {
                  required: false,
                  message: "Please enter sponsor url",
                },
                {
                  type: "url",
                  message: "Please enter valid url.",
                },
              ]}
            >
              <Input className="title" placeholder="please enter sponor url" disabled={sponsor === ""}/>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="discard-btn"
                // onClick={resetForm}
                onClick={() => navigate("/home/home-rotator")}
              >
                Cancel
              </Button>
              <Button
                type="default"
                htmlType="submit"
                // type="text"
                style={{
                  margin: "0 8px",
                  background: darkButtonBackground,
                }}
                onClick={() => clickNext()}
                className="dark-base-btn"
              >
                Next
              </Button>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              {...formItemLayout}
              name="detail_title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title.",
                },
              ]}
            >
              <Input
                className="title"
                onChange={(e) => setDetailTitle(e.target.value)}
                placeholder="Please enter title"
                maxLength={255}
              />
            </Form.Item>
            {/* <Form.Item name={"visibility"}>
                  <Checkbox className="custom-checkbox">Visible to coaches only</Checkbox>
                </Form.Item> */}
            <Form.Item
              {...formItemLayout}
              label="Add Media"
              className="media-div"
              rules={[
                {
                  required: true,
                  message: "Please enter button text.",
                },
              ]}
            >
              <div
                className={
                  videoPreview || detailImagePreview ? "uploader-div" : ""
                }
              >
                <Dragger
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                  className={
                    selectedSport === 1 ? "orange-dragger" : "blue-dragger"
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <img
                      src={UploadFile}
                      alt=""
                      className={
                        selectedSport === 1
                          ? "orange-theme-icon"
                          : "blue-theme-icon"
                      }
                    />
                  </p>
                  <div style={{ textAlign: "left", padding: "12px 0" }}>
                    {" "}
                    <p className="ant-upload-text">
                      Drag and Drop or
                      <span>Browse</span>
                    </p>
                    <p className="ant-upload-hint">
                      Upload photo or video (16:9 ratio)
                    </p>
                  </div>
                </Dragger>
                {videoPreview && (
                  <Card
                    hoverable
                    className="video-cards"
                    cover={
                      detailImgSrc ? (
                        <div
                          style={{
                            overflow: "hidden",
                            height: "250px",
                            borderRadius: 8,
                          }}
                        >
                          <span className="top-cancel-btn">
                            <CloseOutlined onClick={resetLandingDragger} />
                          </span>
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="img"
                            src={detailImgSrc}
                          />
                        </div>
                      ) : (
                        <>
                          <span
                            className="top-cancel-btn"
                            style={{
                              display:
                                !videoId || videoId === "" ? "none" : "block",
                            }}
                          >
                            <CloseOutlined onClick={resetLandingDragger} />
                          </span>
                          <div
                            id="video-container"
                            style={{ height: percentage === 100 ? 235 : 235 }}
                          >
                            {percentage !== 100 && (
                              <div
                                className="progress-div"
                                style={{
                                  zIndex: percentage !== "100" ? "1" : "0",
                                  cursor: "default",
                                  marginTop: "-4px",
                                }}
                              >
                                {" "}
                                <Progress
                                  percent={percentage}
                                  status="active"
                                  className="custom-progress-bar"
                                />
                              </div>
                            )}
                            {videoUrl && (
                              <video width="400" controls height="100%">
                                <source src={URL.createObjectURL(videoUrl)} />
                              </video>
                            )}
                          </div>
                        </>
                      )
                    }
                  >
                    {/* <div onClick={handleFullscreen}>
        <img src={Expand} style={{ fontSize: "2rem", color: "white" }} />
      </div> */}

                    <div
                      className="name"
                      title={
                        videoUrl
                          ? videoUrl?.name
                          : detailImageUrl && detailImageUrl?.name
                      }
                    >
                      {videoUrl
                        ? videoUrl?.name
                        : detailImageUrl && detailImageUrl?.name}
                    </div>
                    <div className="size">
                      {videoUrl && videoUrl
                        ? (videoUrl && videoUrl?.size / 1024 ** 2).toFixed(1) +
                          "MB"
                        : detailImageUrl &&
                          (
                            detailImageUrl && detailImageUrl?.size / 1000
                          ).toFixed(1) + "KB"}
                    </div>
                  </Card>
                )}
              </div>
            </Form.Item>
            {/* <Form.Item                 {...formItemLayout}

name={"auto_change"} label="Autochange Rotator After" className="custom-label">
                <Input addonBefore={"sec"} placeholder="Please enter seconds" className="add-on-input"/>
              </Form.Item> */}
            {detailMediaError === true && (
              <span className="error-message" style={{ marginTop: 5 }}>
                Please upload image/video.
              </span>
            )}
            <Form.Item
              {...formItemLayout}
              name="editor"
              label="Body Text"
              className="custom-label"
            >
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={detailContent}
                className="custom-toolbar"
                onChange={handleProcedureContentChange}
                placeholder="Describe the home rotator..."
              />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="detail_action_button_text"
              label="Button Text"
              className="custom-label"
              rules={[
                {
                  required: true,
                  message: "Please enter button text.",
                },
              ]}
            >
              <Input
                className="title"
                onChange={(e) => setDetailButtonText(e.target.value)}
                placeholder="Please enter button text"
                maxLength={255}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="button_url"
              label="Button URL"
              className="custom-label"
              rules={[
                {
                  required: true,
                  message: "Please enter button url.",
                },
                {
                  type: "url",
                  message: "Please enter valid url",
                },
              ]}
            >
              <Input
                className="title"
                onChange={(e) => setDetailButtonLink(e.target.value)}
                placeholder="Please enter button url"
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className="discard-btn"
                // onClick={resetForm}
                onClick={() => navigate("/home/home-rotator")}
              >
                Cancel
              </Button>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const {
                    button_url,
                    detail_action_button_text,
                    detail_title,
                  } = getFieldsValue();
                  const detailMedia =
                    detailImageUrl || detailVideoId !== "" ? true : false;
                  const formIsComplete =
                    !!button_url &&
                    !!detail_action_button_text &&
                    !!detail_title &&
                    !form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0 &&
                    !!detailMedia;
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!formIsComplete || buttonDisabled}
                      style={{
                        margin: "0 8px",
                        background: darkButtonBackground,
                      }}
                      className="dark-base-btn"
                    >
                      Save
                    </Button>
                  );
                }}
              </Form.Item>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};

const CreateNewRotator = () => {
  return (
    <CreateRotatorContextProvider>
      <CreateNewRotatorPage />
    </CreateRotatorContextProvider>
  );
};

export default CreateNewRotator;
