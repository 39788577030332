
import React, { useState, useRef, useContext, useEffect } from "react";
import { Collapse, Col, Row, Card, Button, Progress, Table } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Expand from "../../../assets/images/expand.svg";
import CollapseIcon from "../../../assets/images/collapse.svg";
import Video from "../../../assets/Snail.mp4";
import Play from "../../../assets/images/play.svg";
import Profile from "../../../assets/images/avatar.svg";
import Avatar from "react-avatar";
import { render } from "@testing-library/react";
import { WorkoutDetailContext } from "../../../context/workouts-context/workout-detail-context";
import { UserContext } from "../../../context/user-context";
const Tasks = ({ tasks, setViewSubmissions , generalDetail }) => {
  const {selectedSport , color} = useContext(UserContext)
  const { setTaskDetail, getSubmissions , setSubmissionRecord , submissionRecord} = useContext(WorkoutDetailContext)
  const { Panel } = Collapse;
  const [playButton, setPlayButton] = useState(true);
  const vidRef = useRef(null);
  const style = {
    padding: "5px 0",
  };
  const handlePlayVideo = () => {
    setPlayButton(false);
    vidRef.current.play();
  };
  const onChange = (key) => {
  };

  const trainingTaskcolumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.avatar !== null ? (
            <img src={record.avatar}></img>
          ) : (
            <Avatar
              name={record.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          )}
          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Reps",
      dataIndex: "reps",
      render: (text, record) => (
        <span>
          {record.reps_done}/{record.total_reps}
        </span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        <span>
          {record.completion_percentage}%
        </span>
      ),
    },
    {
      render: (record) => {
        return(
        <div style={{ display: "flex" }}>
          <div
            style={{ cursor: "pointer" , color : color }}
            onClick={() => {
              setSubmissionRecord(record)
              setViewSubmissions(true);
              getSubmissions(record)
            }}
            className="view-submissions"
          >
            View submissions
          </div>
          {record?.recent_upload === true &&
            <span className="new-sub"></span>
          }
        </div>
      )
    }
    }
  ];
  const scoredTaskColumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.avatar !== null ? (
            <img src={record.avatar}></img>
          ) : (
            <Avatar
              name={record.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          )}
          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text, record) => (
        <span>{record.score ? record.score : "N/A"}</span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        <span>
          {record.completion_percentage}%
        </span>
      )
    },
    {
      render: (record) => (
        <div style={{ display: "flex" }}>
          <div
            style={{ cursor: "pointer" , color : color }}
            onClick={() => {
              setSubmissionRecord(record)
              setViewSubmissions(true);
              getSubmissions(record)
            }}
            className="view-submissions"
          >
            View submissions
          </div>
          {record?.recent_upload === true &&
            <span className="new-sub"></span>
          }
        </div>
      )
    }
  ];
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      reps: "11/22",
      completion: "96%",
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    // setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    // selectedRowKeys,
    onChange: onSelectChange,
  }
  return (
    <>
      <h2 className="task-heading">Tasks</h2>
      <Collapse
        onChange={onChange}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          !isActive ? <img src={Expand} /> : <img src={CollapseIcon} className={"minus-icon " + (selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon")} />
        }
        className="collapsible-rows"
      >
        {tasks?.map((item, index) => {
          return (
            <Panel
              key={index}
              onClick={() => setTaskDetail(item)}
              header={
                <div className="collapse-label">
                  <Row style={{ width: "100%" }}>
                    <Col span={16} style={{ fontSize: 16 }}>
                      {item.title} ({item.skill_level})
                      <span>
                        {item.scored_task ? "Scored Task" : "Training Task"}
                      </span>
                      {item.lock &&
                        <div className="task-lock">
                          <LockOutlined style={{ fontSize: 12, color: "rgba(126, 130, 153, 1)" }} />
                        </div>
                      }
                    </Col>
                    <Col span={4} style={{marginTop: "-5px",padding: "0px 10px"}}>
                      {" "}
                      {item.is_competed === true ? (
                        <div className="status">Completed</div>
                      ) : item.is_competed === false &&
                        item.completion_percentage === 0 ? (
                        <button
                          className="yellow-tag"
                          style={{ marginTop: "-3px" }}
                        >
                          Pending
                        </button>
                      ) : (
                        <Progress
                          percent={item.completion_percentage}
                          showInfo={false}
                          className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                        />
                      )}
                    </Col>
                    <Col span={4}>
                      <div className="points">
                        Completed by: <span>{item.completed_by}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            >
              <Table
                rowKey={(obj) => obj.id}
                columns={
                  item.scored_task === true
                    ? scoredTaskColumns
                    : trainingTaskcolumns
                }
                pagination={false}
                dataSource={item.completed_by_members}
                className="tasks-table"
              />
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default Tasks;
