import React, { useContext, useEffect, useState } from "react";
import { getData, formPostData } from "../../util/api-calls";
import CryptoJs from "crypto-js";
import { UserContext } from "../user-context";
import { useNavigate } from "react-router-dom";
import * as tus from "tus-js-client";
import axios from "axios";
import { Form, message } from "antd";
let upload = null;

export const CreateRotatorContext = React.createContext({});
export const CreateRotatorContextProvider = ({ children = {} }) => {
  const { selectedSport } = useContext(UserContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [sponsorModal, setSponsorModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [allRotators, setAllRotators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [sportsList, setSportsList] = useState([]);
  const [sponsorsList, setSponsorsList] = useState([]);
  const [sport, setSport] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [sponsorUrl, setSponsorUrl] = useState("");
  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState();
  const [detailVideoId, setDetailVideoId] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [detailContent, setDetailContent] = useState("");
  const [detailButtonText, setDetailButtonText] = useState("");
  const [detailButtonLink, setDetailButtonLink] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [detailTitle, setDetailTitle] = useState("");
  const [imgSrc, setImgSrc] = useState();
  const [imagePreview, setImagePreview] = useState(false);
  const [videoPreview, setVideoPreview] = useState(false);
  const [detailImgSrc, setDetailImgSrc] = useState();
  const [sportError, setSportError] = useState(false);
  const [detailMediaError, setDetailMediaError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [asssignee, setAssignee] = useState("both");
  // const getSportList = () => {
  //   getData(`sport`)
  //     .then((res) => {
  //       setSportsList(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // React.useEffect(() => {
  //   getSportList();
  // }, []);
  const uploadVideo = async (
    file,
    handleSuccessfullBytes,
    uploadCompleteDone
  ) => {
    console.log("LgssAAAA", file);
    const uploadLink = await createVideo(file.size);
    let bytes = 0;
    upload = new tus.Upload(file, {
      uploadUrl: uploadLink?.upload?.upload_link,
      retryDelays: [1000, 3000, 5000],
      uploadSize: file.size,
      // chunkSize:512 * 1024,
      // headers: {
      //   Accept: "application/vnd.vimeo.*+json;version=3.4",
      //   Authorization: "Bearer be82d2e853d829ed867903ec070a64f7",
      //   "Tus-Resumable": "1.0.0",
      //   "Upload-Offset": "0",
      //   "Content-Type": "application/offset+octet-stream",
      // },
      //   metadata: {
      //     filename: `video.mov`,
      //     filetype: "video/mov",
      //   },
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onShouldRetry: function (err, retryAttempt, options) {
        console.log("onShouldRetry Error", err);
        console.log(" onShouldRetry Request", err.originalRequest);
        console.log(" onShouldRetry Response", err.originalResponse);

        var status = err.originalResponse
          ? err.originalResponse.getStatus()
          : 0;
        // Do not retry if the status is a 403.
        if (status === 403) {
          return false;
        }

        // For any other status code, we retry.
        return true;
      },
      onChunkComplete: (status) => {
        console.log(`upload failed:`, status);
      },
      onError: (error) => {
        console.log("Request", error.originalRequest);
        console.log("Response", error.originalResponse);
        console.log(`upload failed:`, error);
      },
      onProgress: (uploadedBytes, totalBytes) => {
        bytes = uploadedBytes;
        handleSuccessfullBytes(uploadedBytes, totalBytes);
        // console.log(`upload Success ${uploadedBytes} ${totalBytes}`);
      },
      onSuccess: () => {
        console.log(uploadLink);
        uploadCompleteDone(true, uploadLink?.uri, uploadLink.link);
        console.log("Upload URL:", upload.url);
      },
    });

    upload.start();
  };

  const abortVideo = () => {
    upload?.abort();
  };
  const createVideo = async (fileSize) => {
    const params = {
      upload: {
        approach: "tus",
        size: fileSize,
      },
      name: "My Video",
    };
    const response = await axios.post(
      "https://api.vimeo.com/me/videos",
      params,
      {
        headers: {
          Accept: "application/vnd.vimeo.*+json;version=3.4",
          "Content-Type": "application/json",
          Authorization: `Bearer be82d2e853d829ed867903ec070a64f7`,
        },
      }
    );

    console.log("LogggggAAA", response);
    setVideoId(response.data.uri);
    return response?.data;
  };
  let mediaType = "";
  const createRotator = (values) => {
    setButtonDisabled(true);
    if (detailImageUrl) {
      mediaType = "image";
    }
    if (videoUrl) {
      mediaType = "video";
    }
    // if(sport === ""){
    //   setSportError(true)
    // }else
    if (mediaType === "") {
      setDetailMediaError(true);
    } else {
      setSportError(false);
      let start_date, end_date;
      if (startDate !== "") {
        start_date = startDate;
      }
      if (endDate !== "") {
        end_date = endDate;
      }

      let formData = new FormData();
      formData.append("title", title);
      formData.append("sport_id", selectedSport);
      formData.append("sponsor_id", sponsor);
      formData.append("action_button_text", buttonText);
      if (imageUrl) {
        formData.append("media_image", imageUrl);
      }
      formData.append("detail_media_uri", detailVideoId);
      formData.append("detail_title", detailTitle);
      if (detailImageUrl) {
        formData.append("detail_image", detailImageUrl);
      }
      if (mediaType) {
        formData.append("detail_media_type", mediaType);
      }
      formData.append("detail_content", detailContent);
      formData.append("detail_action_button_text", detailButtonText);
      formData.append("detail_action_button_link", detailButtonLink);
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("display_to",asssignee)
      formPostData(`slide`, formData)
        .then((res) => {
          if (res && res.status === 200) {
            console.log(res);
            resetForm();
            form.resetFields();
            message.success({
              content: "Rotator created successfully.",
              duration: 1,
            });
            // setTimeout(() => {
            navigate("/home/home-rotator");
            // }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setLoading(false);
          setButtonDisabled(false);
        });
    }
  };
  const resetForm = () => {
    setSport("");
    setImageUrl();
    setDetailVideoId("");
    setTitle("");
    setSponsor("");
    setButtonText("");
    setDetailTitle("");
    setDetailContent("");
    setDetailButtonLink("");
    setDetailButtonText("");
    setStartDate("");
    setEndDate("");
    setDetailImageUrl();
    setDetailImgSrc();
    setVideoUrl();
    setVideoPreview(false);
    setImagePreview(false);
    setImgSrc();
    form1.resetFields();
    form.resetFields();
  };
  const getAllSponsors = () => {
    getData(`sponsor`)
      .then((res) => {
        console.log(res);
        setSponsorsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllSponsors();
  }, []);
  const defaultContext = {
    sponsorsList,
    uploadVideo,
    sponsorModal,
    setSponsorModal,
    pageNumber,
    setPageNumber,
    search,
    setSearch,
    pageSize,
    setPageSize,
    loading,
    setLoading,
    total,
    allRotators,
    sportsList,
    sport,
    setSport,
    sponsor,
    setSponsor,
    sponsorUrl,
    setSponsorUrl,
    startDate,
    endDate,
    videoId,
    setVideoId,
    abortVideo,
    setButtonText,
    setStartDate,
    videoPreview,
    resetForm,
    videoUrl,
    detailContent,
    form1,
    form,
    imagePreview,
    detailImgSrc,
    imageUrl,
    setTitle,
    setDetailVideoId,
    detailVideoId,
    setDetailButtonLink,
    setDetailButtonText,
    setDetailContent,
    setImageUrl,
    setImgSrc,
    setDetailImgSrc,
    setVideoUrl,
    setImagePreview,
    setDetailImageUrl,
    setDetailTitle,
    setEndDate,
    setVideoPreview,
    imgSrc,
    createRotator,
    detailImageUrl,
    title,
    buttonText,
    sportError,
    setSportError,
    detailMediaError,
    setDetailMediaError,
    buttonDisabled,
    asssignee,
    setAssignee,
  };
  return (
    <CreateRotatorContext.Provider value={defaultContext}>
      {children}
    </CreateRotatorContext.Provider>
  );
};
